.mistho-form-wrapper {
  .mistho-form {
    height: 100%;
    width: 100%;
  }

  .mistho-provider__logo {
    display: none;
  }

  .mistho-provider__info {
    display: none;
  }

  .mistho-field__control {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 2rem;
    flex-grow: 1;

    .mistho-field__label {
      margin-bottom: 0.5rem;
    }

    input {
      padding: 1rem;
      border: 1px solid currentColor;
      border-radius: 0.5rem;

      ::placeholder {
        color: currentColor;
      }
    }

    svg {
      display: none;
    }
  }

  .mistho-field__password-toggler {
    svg {
      display: block;
      position: absolute;
      right: 2rem;
      margin-top: 0.75rem;
      fill: currentColor;
    }
  }

  .mistho-forgot-password {
    display: block;
    margin-bottom: 2rem;
  }

  .mistho-checkbox {
    margin-top: 2rem;
    margin-bottom: 1rem;

    &__input {
      display: block;
      height: 0;
      width: 0;
    }

    &__element {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 2.25rem;
      width: 2.25rem;
      box-sizing: border-box;
      cursor: pointer;
      border: 0.125rem solid rgb(153, 154, 255);
      background: rgb(255, 255, 255);
      border-radius: 0.5rem;
      transition: all 150ms ease 0s;

      &__check {
        max-width: 0;
        opacity: 0;
        transition: 0.33s ease;
        transition-delay: 0ms;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__label {
      padding-left: 2.75rem;
      font-size: medium;
      font-weight: bold;
      margin-top: -2rem;
    }
  }

  .mistho-btn {
    transition: all 0.25s ease-in-out 0s;
    border-style: solid;
    border-radius: 0.5rem;
    font-weight: 700;
    font-family: 'GT Walsheim', 'Helvetica Neue', sans-serif;
    width: 100%;
    border-width: 0.125rem;
    padding: 1rem 1.5rem;
    font-size: 1.25rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mistho-checkbox__input:checked + .mistho-checkbox__element {
    transition-delay: 0ms;
  }

  .mistho-checkbox__input:focus + .mistho-checkbox__element {
    border-color: currentColor;
  }

  .mistho-checkbox__input:checked
    + .mistho-checkbox__element
    .mistho-checkbox__element__check {
    opacity: 1;
    max-width: 2rem;
    transition-delay: 175ms;
  }

  .mistho-field__error,
  .mistho-error {
    font-style: normal;
    font-family: 'GT Walsheim', 'Helvetica Neue', sans-serif;
    font-size: 0.875rem;
    color: rgb(175, 24, 48);
    margin-top: -2rem;
    margin-bottom: 2rem;
  }

  .mistho-error {
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .mistho-checkbox__error {
    font-style: normal;
    font-family: 'GT Walsheim', 'Helvetica Neue', sans-serif;
    font-size: 0.875rem;
    color: rgb(175, 24, 48);
  }

  &-finio {
    .mistho-btn {
      background-color: rgb(255, 236, 131);
      border-color: rgb(255, 236, 131);
      color: rgb(77, 48, 176);

      &:focus {
        box-shadow: 0 0 0 0.125rem #69b7ff;
      }
    }

    .mistho-checkbox__input:checked
      + .mistho-checkbox__element
      .mistho-checkbox__element__check {
      svg {
        fill: rgb(0, 235, 217);
        stroke: rgb(0, 235, 217);
        transform: scale(2);
      }
    }

    .mistho-checkbox__input:checked + .mistho-checkbox__element {
      background-color: currentColor;
      border-color: currentColor;
    }
  }

  &-oakbrook {
    .mistho-btn {
      background-color: rgb(0, 255, 218);
      border-color: rgb(0, 255, 218);
      color: rgb(0, 0, 103);

      &:focus {
        box-shadow: 0 0 0 0.125rem #fcbb29;
      }
    }

    .mistho-checkbox__input:checked
      + .mistho-checkbox__element
      .mistho-checkbox__element__check {
      svg {
        fill: #fff;
        stroke: #fff;
        transform: scale(2);
      }
    }

    .mistho-checkbox__input:checked + .mistho-checkbox__element {
      background-color: rgb(0, 0, 103);
      border-color: rgb(0, 0, 103);
    }
  }
}

.provider-wrapper {
  .mistho-provider__logo {
    font-size: 46px;
    width: 1.25em;
    height: 1em;
    flex: 0 0 1.25em;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    overflow: hidden;
  }
  .mistho-provider__logo--loading {
    position: relative;
    border-color: transparent;
  }
  .mistho-provider__logo--loading:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(-100%);
    opacity: 0;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  .mistho-provider__logo--fallback {
    background-color: #ebecec;
    color: #fff;
  }
  @media (max-width: 991.98px) {
    .mistho-provider__logo {
      margin-right: 10px;
    }
  }
  .mistho-provider__logo__img {
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
    object-position: center;
  }
  .mistho-provider__logo--loading .mistho-provider__logo__img {
    opacity: 0;
  }
  .mistho-provider__item--login .mistho-provider__item__content {
    border: 0;
    padding: 0;
  }
  .mistho-provider__item--login .mistho-provider__logo {
    margin-right: 20px;
  }
  .mistho-provider__item--login .mistho-provider__info {
    margin-right: 0;
  }
  .mistho-provider__item {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
  }
  .mistho-provider__item--login {
    margin-bottom: 22px;
    cursor: unset;
  }
  .mistho-provider__item__hint {
    margin-left: auto;
    align-self: center;
    display: flex;
    padding: 10px;
    margin-right: 0;
  }
  @media (hover: hover) {
    .mistho-provider__item:hover .mistho-btn,
    .mistho-provider__item:focus .mistho-btn {
      background-color: #e4eaee;
    }
  }
  .mistho-provider__item__content {
    display: flex;
    flex-grow: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e4eaee;
    align-items: center;
    justify-content: space-between;
  }
  .mistho-provider__item:last-child .mistho-provider__item__content {
    border-color: transparent;
  }
  .mistho-provider__info {
    margin-right: 8px;
  }
  .mistho-provider__item_content {
    flex-grow: 1;
  }
  .mistho-provider__login-title {
    color: #393e46;
    margin-bottom: 10px;
  }
  .mistho-provider__name {
    margin-bottom: 3px;
  }
  .mistho-provider__item--skeleton .mistho-provider__item__content {
    border-color: transparent;
  }
  .mistho-provider__item--skeleton .mistho-provider__logo {
    position: relative;
    background-color: #ebecec;
    border-color: transparent;
  }
  .mistho-provider__item--skeleton .mistho-provider__logo:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(-100%);
    opacity: 0;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  .mistho-provider__item--skeleton .mistho-provider__login-title {
    position: relative;
    background-color: #ebecec;
    height: 22px;
    width: 65px;
    border-radius: 3px;
  }
  .mistho-provider__item--skeleton .mistho-provider__login-title:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(-100%);
    opacity: 0;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  .mistho-provider__item--skeleton .mistho-provider__name {
    position: relative;
    background-color: #ebecec;
    height: 22px;
    width: 220px;
    border-radius: 3px;
  }
  .mistho-provider__item--skeleton .mistho-provider__name:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(-100%);
    opacity: 0;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  .mistho-provider__item--skeleton .mistho-provider__type {
    position: relative;
    background-color: #ebecec;
    height: 16px;
    margin-top: 5px;
    width: 105px;
    border-radius: 3px;
  }
  .mistho-provider__item--skeleton .mistho-provider__type:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate(-100%);
    opacity: 0;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
  @keyframes shimmer {
    50% {
      opacity: 1;
    }
    to {
      transform: translate(100%);
    }
  }
  .mistho-provider__list {
    display: flex;
    flex-direction: column;
  }

  .mistho-sr-only {
    display: none;
  }

  .mistho-box__search {
    transition: opacity 0.2s linear;
    max-height: 45px;
    top: 114px;
    z-index: 2;
  }
  .mistho-box__search--anim-delay {
    transition: all 0.2s linear, opacity 0.2s linear 0.5s;
  }
  @media (min-width: 992px) {
    .mistho-box__search {
      top: 70px;
    }
  }
  .mistho-box--Welcome .mistho-box__search {
    max-width: 100%;
  }
  .mistho-box--Providers .mistho-box__search {
    max-height: none;
    width: 100%;
  }
  .android .mistho-box--Providers .mistho-box__search {
    position: static;
  }
  .mistho-box--Providers .mistho-box__search__bar {
    background-color: #fff;
  }
  .mistho-box--Providers .mistho-box__search--with-filter {
    padding-bottom: 5px;
  }
  .mistho-box:not(.mistho-box--Providers, .mistho-box--Welcome, .mistho-box--Initializer)
    .mistho-box__search {
    display: none;
  }
  .mistho-box__search__bar {
    display: grid;
    grid-template-columns: 1fr 18px 64px;
    height: 45px;
    background-color: #f7f9fc;
    border-radius: 8px;
    transition: 0.2s linear, border 0.2s ease;
    position: relative;
    z-index: 3;
    margin-bottom: 1rem;
  }
  .mistho-box__search__bar:focus-within {
    border-color: #a7afee;
  }
  .mistho-box__search__bar__input {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    grid-column: 1/4;
    grid-row: 1;
    padding-left: 20px;
    padding-right: 82px;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid currentColor;
    border-radius: 0.5rem;
  }
  .mistho-box__search__bar__input:placeholder-shown {
    padding-right: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .mistho-box__search__bar__input::-webkit-search-decoration,
  .mistho-box__search__bar__input::-webkit-search-cancel-button,
  .mistho-box__search__bar__input::-webkit-search-results-button,
  .mistho-box__search__bar__input::-webkit-search-results-decoration {
    display: none;
  }
  @media (hover: none) {
    .mistho-box--Welcome .mistho-box__search__bar__input {
      pointer-events: none;
    }
  }
  .mistho-box__search__bar .mistho-icon--loupe {
    align-self: center;
    justify-self: center;
    grid-column: 3/4;
    grid-row: 1;
    pointer-events: none;
  }
  .mistho-box__search__bar__reset {
    align-self: center;
    justify-self: center;
    grid-column: 2/3;
    grid-row: 1;
    cursor: pointer;
  }
  .mistho-box__search__filters {
    gap: 5px;
    padding-top: 12px;
    margin-bottom: 1rem;
    display: flex;

    &__scroll {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    &__scroll::-webkit-scrollbar {
      display: none;
    }
  }

  .mistho-screen__providerlist_scrollable-wrapper {
    scrollbar-width: none;
    -ms-overflow-style: none;
    height: 400px;
    overflow: auto;
    background-color: #f9f9f9;
    padding: 1rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .mistho-box__search__filters .mistho-btn__text {
    white-space: nowrap;
  }
  .mistho-box__search__filters__wrapper {
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
    border-radius: 16px;
  }

  .mistho-box__search__filters__scroll {
    overflow: auto;
    padding-bottom: 7px;
    padding-left: 10px;
    display: flex;
    position: relative;
  }
  .mistho-box__search__filters__scroll::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .mistho-box__search__filters__scroll:after {
    content: '';
    display: block;
    width: 10px;
    flex: 0 0 10px;
    height: 1px;
  }
  .mistho-box__search__text {
    max-width: 460px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: 0.2s linear;
  }
  @media (max-width: 991.98px) {
    .mistho-box__search__text {
      max-width: 225px;
      padding-top: 8px;
    }
  }

  .mistho-btn--tag {
    width: 100px;
    border: 1px solid currentColor;
    border-radius: 2rem;
    background-color: white;
    padding: 4px;
  }

  .mistho-btn--has-icon {
    svg {
      display: none;
    }
  }

  .mistho-btn--icon {
    background-color: transparent;

    svg {
      fill: currentColor;
    }
  }

  .mistho-provider__type {
    color: #393e46;
    font-size: 0.875rem;
  }

  .mistho-btn--tag-active {
    background-color: currentColor;

    .mistho-btn__text {
      color: #fff;
    }
  }
}

.white-button {
  color: #fff !important;
  border-color: #fff !important;
  text-align: left;

  &:hover {
    background-color: inherit !important;
    color: inherit;
  }
}
