.text-white {
  color: #fff;
}

.align-steps-mobile {
  @media screen and (max-width: 33rem) {
    padding-top: 1rem;
    margin-bottom: -2rem;
  }
}
